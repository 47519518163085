import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import setupStore from "./store";
import './styles/main.min.css'
import './i18n';

// Lazy load components
const Landing = lazy( () => import( './pages/landing' ) );
const Generic = lazy( () => import( './pages/generic' ) );
const Glassy = lazy( () => import( './pages/glassy' ) );
const Blue = lazy( () => import( './pages/blue' ) );
const MobileGlassy = lazy( () => import( './pages/glassy/mobile' ) );
const MobileBlue = lazy( () => import( './pages/blue/mobile' ) );
const FlyerScreen = lazy( () => import( './pages/flyerScreen' ) );
const Index = lazy( () => import( './pages/prayers/modern' ) );
const NotFound = lazy( () => import( './pages/notFound' ) );

const ModernWraper = () => <Index ads={ false } />;

function App () {
  const store = setupStore();
  return (
    <Provider store={ store }>
      <Router>
        <Suspense fallback={ <></> }>
          <Routes>
            <Route path="/use/generic" element={ <Generic /> } />
            <Route path="/" element={ <Landing /> } />
            <Route path="/glassy/:token" element={ <Glassy /> } />
            <Route path="/blue/:token" element={ <Blue /> } />
            <Route path="/mobile/glassy/:token" element={ <MobileGlassy /> } />
            <Route path="/mobile/blue/:token" element={ <MobileBlue /> } />
            <Route path="/:digits/trioframe" element={ <FlyerScreen /> } />
            <Route path="/noads/:token" element={ <ModernWraper /> } />
            <Route path="/:token" element={ <Index /> } />
            <Route path="/:token/view/1" element={ <Index /> } />
            <Route path="/:token/view/1/:time" element={ <Index /> } />
            <Route path="/:token/view/1/:time/:reload" element={ <Index /> } />
            <Route path="/:token/view/2" element={ <Index /> } />
            <Route path="/404" element={ <NotFound /> } />
            <Route path="*" element={ <NotFound /> } />
          </Routes>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;